.popup__text-info {
  color: #d9d9da;
  padding-left: 55px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  overflow-y: auto;
  height: 290px;
  width: 370px;
}
.popup__text-info span.small {
  font-size: 11.5px;
}