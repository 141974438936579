.layer-1 {
  height: 20px;
  width: 100%;
  background: rgba(107, 60, 3, 0.8);
  color: #fff;
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
}
