.InfoPopup {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.5s linear;
}
