.check-listings {
  background-color: #70c0e8;
  height: 35px;
  border-radius: 5px;
  border: none;
}

.check-listings:hover {
  background-color: #7fc6ea;
  transition: 0.3s;
  cursor: pointer;
}
