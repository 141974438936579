.btn {
    height: 34px;
    font-size: 13px;
    border: 1px solid #585858;
    background-color: #242424;
    color: #D7D7D7;
    transition: all .5s;
    padding-left: 15px;
    padding-right: 15px;
}
.btn:hover {
    border: 1px solid #F7931A;
    color: #F7931A;
    cursor: pointer;
}