.go {
  color: #70c0e8;
  background-color: #303D43;
  border: none;
  border-radius: 4px;
  height: 28px;
  padding: auto 10px;
  transition: all .3s;
  cursor: pointer;
}
.go:hover {
  background-color: #33434B;
}
