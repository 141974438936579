.page {
  font-family: Arial, sans-serif, "Times New Roman", Times, serif;
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #242424;
  margin: auto;
  color: #B5B5B5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}
