.view-punks {
  margin: 40px auto 55px;
  height: 35px;
  background-color: #353535;
  border: none;
  color: #b5b5b5;
  border-radius: 4px;
  transition: all 0.3s;
}

.view-punks:hover {
  background-color: #242424;
  cursor: pointer;
}
