.popup__container-info {
    position: relative;
    box-sizing: border-box;
    width: 600px;
    min-height: 275px;
    max-height: 457px;
    background-color: #2c2c32;
    border-radius: 5px;
    color: #000;
    padding: 25px 40px 30px;
    display: flex;
    flex-direction: column;
  }