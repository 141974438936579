.Confirmation {
  width: 600px;
  border: 1px solid #2f5876;
  margin: 0 auto;
  color: #d8dcdf;
  background-color: #293d4c;
  font-size: 13px;
  line-height: 22px;
  display: flex;
}
