.panel-row {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1023px) {
  .panel-row {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
}
