.load-cards {
  margin-top: 20px;
  margin: 0 auto 40px;
  height: 35px;
  background-color: #353535;
  border: none;
  color: #F7931A;
  border-radius: 4px;
  transition: all 0.3s;
}
.load-cards:hover {
  background-color: #3F3F3F;
  cursor: pointer;
}
